import axios from 'axios';

const config = {
  baseURL: 'http://176.88.250.65:8081/api',
  method: 'post',
  timeout: 10000,
  withCredentials: false,
  transformResponse: [(data) => {
    const d = JSON.parse(data);
    return d.body;
  }],
  transformRequest: [data => JSON.stringify(data)],
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=UTF-8',
    withCredentials: false,
    'Access-Control-Allow-Origin': '*',
  },
};

export default axios.create(config);
