/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import DataPaginationTable from '../../../../shared/components/table/DataPaginationTable';

export default class DataTable extends Component {
  static propTypes = {
    data: PropTypes.instanceOf(Array).isRequired,
  };

  constructor(props) {
    super(props);
    this.heads = [
      {
        key: 'title',
        name: '',
        width: '100%',
        sortable: false,
      },
      {
        key: 'value',
        name: '',
        width: 60,
        sortable: false,
      },
      {
        key: 'option',
        name: '',
        width: 30,
        sortable: false,
      },
    ];
    this.state = {
      rows: [],
      rowsToShow: [],
      pageOfItems: 1,
      itemsToShow: 1,
    };
  }

  componentDidMount() {
    const { data } = this.props;
    this.arrangeTable(data);
  }

  componentWillReceiveProps(props) {
    const { data } = props;
    this.arrangeTable(data);
  }

  filterRows = (originalRows, pageNumber, rowsOnPage) => {
    const rowsFrom = rowsOnPage * (pageNumber - 1);
    const rowsTo = rowsFrom + rowsOnPage;
    return originalRows.slice(rowsFrom, rowsTo);
  };

  onSorting = (sortColumn, sortDirection) => {
    const comparer = (a, b) => {
      if (sortDirection === 'ASC') {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      }
      return a[sortColumn] < b[sortColumn] ? 1 : -1;
    };
    const {
      rows, pageOfItems, itemsToShow,
    } = this.state;
    if (sortDirection !== 'NONE') {
      let sortRows = [...rows].sort(comparer);
      sortRows = this.filterRows(sortRows, pageOfItems, itemsToShow);
      this.setState({ rowsToShow: sortRows });
      return sortRows;
    }
    const sortRows = this.filterRows(rows, pageOfItems, itemsToShow);
    this.setState({ rowsToShow: sortRows });
    return sortRows;
  };

  arrangeTable(data) {
    // const { data } = this.props;
    const initialPageNumber = 1;

    const originalRows = [];
    data.forEach((val) => {
      originalRows.push({
        title: val.title,
        value: val.value,
        option: '-',
      });
    });
    const initialRowsCount = originalRows.length;

    this.setState({
      rows: originalRows,
      rowsToShow: originalRows,
      pageOfItems: initialPageNumber,
      itemsToShow: initialRowsCount,
    });
  }

  render() {
    const {
      rowsToShow,
    } = this.state;
    const cardBodyStyle = {
      padding: 0,
      width: '100%',
    };
    return (
      <div>
        <Card>
          <CardBody style={cardBodyStyle}>
            <DataPaginationTable
              heads={this.heads}
              rows={rowsToShow}
              onSorting={this.onSorting}
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}
