import React, { Component } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import API from '../../../shared/Api';
import PointSizes from './components/PointSizes';
import RandomAnimatedLine from './components/RandomAnimatedLine';
import RandomAnimatedBars from './components/RandomAnimatedBars';
import PolarArea from './components/PolarArea';
import DynamiclyRefreshedDoughnut from './components/DynamiclyRefreshedDoughnut';
// import GoogleGauge from './components/GoogleGauge';
import RadialGauge from './components/RadialGauge';
import LegendHandlers from './components/LegendHandlers';

class KompresorDashboard extends Component {
  gaugeIntervalID = null;

  chartIntervalID = null;

  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      gauges: [
        {
          type: 'ortalama_set',
          title: 'Ortalama SET',
          value: 0,
          maxVal: 5,
          ticks: 0.5,
          formatText: false,
        }, {
          type: 'toplam_maliyet',
          title: 'Toplam Maliyet',
          value: 0,
          maxVal: 100,
          ticks: 10,
          formatText: 'currency',
        }, {
          type: 'toplam_tuketim',
          title: 'Toplam Tüketim',
          value: 0,
          maxVal: 500,
          ticks: 50,
          formatText: false,
        }, {
          type: 'ortalama_verim',
          title: 'Ortalama Verim',
          value: 0,
          maxVal: 100,
          ticks: 10,
          formatText: false,
        }],
      hourlyChart: {
        initialLoadComplete: false,
        title: 'SAATLİK KOPRESSÖR VERİMLİLİĞİ',
        data: {
          labels: [],
          datasets: [],
        },
      },
    };
  }

  componentDidMount() {
    this.gaugesFunc();
    this.chartFunc();
    this.gaugeIntervalID = setInterval(() => {
      this.gaugesFunc();
    }, 3000);
    this.chartIntervalID = setInterval(() => {
      this.chartFunc();
    }, 10000);
  }

  componentWillUnmount() {
    if (this.gaugeIntervalID !== null) {
      clearInterval(this.gaugeIntervalID);
    }
    if (this.chartIntervalID !== null) {
      clearInterval(this.chartIntervalID);
    }
  }

  gaugesFunc = () => {
    const { gauges } = this.state;
    API({
      url: '/metrics/value',
      data: {
        metricId: 1004,
        body: [
          { paramType: 'String', paramValue: 25 },
          { paramType: 'String', paramValue: 9 },
          { paramType: 'String', paramValue: 45 },
          { paramType: 'String', paramValue: 43 },
        ],
      },
    }).then((response) => {
      const gVal = response.data.reduce((tot, arr) => tot + parseFloat(arr.param[1]), 0);
      gauges[0].value = (gVal / response.data.length).toFixed(4);
      this.setState(prevState => ({
        ...prevState,
        gauges,
      }));
    });
    API({
      url: '/metrics/value',
      data: {
        metricId: 1007,
        body: [{ paramType: 'Integer', paramValue: 30 }],
      },
    }).then((response) => {
      const finalVal = parseFloat(response.data[0].param[0]);
      gauges[1].value = Number.isNaN(finalVal) ? 0 : (finalVal / 1000000);
      this.setState(prevState => ({
        ...prevState,
        gauges,
      }));
    });
    API({
      url: '/metrics/value',
      data: {
        metricId: 1011,
        body: [{ paramType: 'Integer', paramValue: 25 }],
      },
    }).then((response) => {
      const finalVal = parseFloat(response.data[0].param[0]);
      gauges[2].value = Number.isNaN(finalVal) ? 0 : finalVal;
      this.setState(prevState => ({
        ...prevState,
        gauges,
      }));
    });
    API({
      url: '/metrics/value',
      data: {
        metricId: 1029,
        body: [{ paramType: 'Integer', paramValue: 25 }],
      },
    }).then((response) => {
      const finalVal = parseFloat(response.data[0].param[0]);
      gauges[3].value = Number.isNaN(finalVal) ? 0 : finalVal;
      this.setState(prevState => ({
        ...prevState,
        gauges,
      }));
    });
  };

  chartFunc = () => {
    const chartColorSpecs = [
      '#ee6013', '#a7f38c', '#e20794', '#dfb48b', '#79a9f1', '#30e20b', '#ca1a26', '#373c99', '#448a99', '#fed93b',
    ];
    const chartLabels = [];
    const chartData = [];
    const chartAddedSpecs = [];
    API({
      url: '/metrics/value',
      data: {
        metricId: 1014,
        body: [{ paramType: 'Integer', paramValue: 25 }],
      },
    }).then((response) => {
      response.data.forEach((val) => {
        let item = false;
        if (chartAddedSpecs.indexOf(parseInt(val.param[1], 10)) === -1) {
          const color = chartColorSpecs.shift();
          chartAddedSpecs.push(parseInt(val.param[1], 10));
          item = {
            fill: false,
            lineTension: 0.3,
            borderWidth: 2,
            pointHoverBorderWidth: 1,
            data: [],
            title: val.param[2],
            label: val.param[2],
            titleCode: parseInt(val.param[1], 10),
            backgroundColor: color,
            borderColor: color,
          };
        }
        if (item !== false) {
          chartData.push(item);
        }
        const timeData = new Date(val.param[0]);
        const timeLabel = `${timeData.getHours()}:${timeData.getMinutes()}`;
        if (chartLabels.indexOf(timeLabel) === -1) {
          chartLabels.push(timeLabel);
        }
        chartData.forEach((cVal, cKey) => {
          if (cVal.titleCode === parseInt(val.param[1], 10)) {
            chartData[cKey].data.push((Number.isNaN(parseFloat(val.param[3])) ? 0 : parseFloat(val.param[3])));
            // chartData[cKey].data.shift();
          }
        });
      });
      this.setState(prevState => ({
        hourlyChart: {
          ...prevState.hourlyChart,
          initialLoadComplete: true,
          data: {
            labels: chartLabels,
            datasets: chartData,
          },
        },
      }));
    });
  };

  render() {
    const {
      t,
    } = this.props;
    const {
      gauges,
      hourlyChart,
    } = this.state;
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">{t('dashboard_kompresor.page_title')}</h3>
            <h3 className="page-subhead subhead">Kompresör ile ilgili tüm bilgiler anlık olarak gösterilir.
            </h3>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <Row>
                  {gauges.map(g => (
                    <Col lg={3} md={6} key={g.type}>
                      <RadialGauge
                        key={g.type}
                        metrics={g}
                      />
                    </Col>
                  ))}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <PointSizes metrics={hourlyChart} />
          <RandomAnimatedLine />
          <RandomAnimatedBars />
          <DynamiclyRefreshedDoughnut />
          <PolarArea />
          <LegendHandlers />
        </Row>
      </Container>
    );
  }
}

export default (withTranslation('common')(KompresorDashboard));
