import React from 'react';
import { Route, Switch } from 'react-router-dom';
import KompresorIzleme from '../../../Kompresor/Izleme/index';
import KompresorAnlik from '../../../Kompresor/Anlik/index';
import KompresorTablo1 from '../../../Kompresor/Tablo1/index';
import KompresorFabrika from '../../../Kompresor/Fabrika/index';

export default () => (
  <Switch>
    <Route path="/kompresor/izleme" component={KompresorIzleme} />
    <Route path="/kompresor/anlik" component={KompresorAnlik} />
    <Route path="/kompresor/tablo1" component={KompresorTablo1} />
    <Route path="/kompresor/fabrika" component={KompresorFabrika} />
  </Switch>
);
