import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ElektrikIzleme from '../../../Elektrik/Izleme/index';
import ElektrikAnlik from '../../../Elektrik/Anlik/index';
import ElektrikTablo1 from '../../../Elektrik/Tablo1/index';
import ElektrikFabrika from '../../../Elektrik/Fabrika/index';

export default () => (
  <Switch>
    <Route path="/elektrik/izleme" component={ElektrikIzleme} />
    <Route path="/elektrik/anlik" component={ElektrikAnlik} />
    <Route path="/elektrik/tablo1" component={ElektrikTablo1} />
    <Route path="/elektrik/fabrika" component={ElektrikFabrika} />
  </Switch>
);
