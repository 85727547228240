import React, { PureComponent } from 'react';
import {
  Card, Col, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import ProfileTasks from './ProfileTasks';
import showResults from './Show';
import ProfileSettings from './ProfileSettings';

class ProfileTabs extends PureComponent {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
    };
  }

  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const initialValues = {
      username: 'Besim Vatan',
      email: 'besimvatan@mail.com',
    };
    const { activeTab } = this.state;
    return (
      <Col md={12} lg={12} xl={8}>
        <Card>
          <div className="profile__card tabs tabs--bordered-bottom">
            <div className="tabs__wrap">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => {
                      this.toggle('1');
                    }}
                  >
                    PROFİL AYARLARI
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => {
                      this.toggle('2');
                    }}
                  >
                    TEMA AYARLARI
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '3' })}
                    onClick={() => {
                      this.toggle('3');
                    }}
                  >
                    GÖSTERGE PANELİ AYARLARI
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <ProfileSettings onSubmit={showResults} initialValues={initialValues} />
                </TabPane>
                <TabPane tabId="2">
                  <button className="sidebar__link" type="button" onClick="">
                    <p className="sidebar__link-title">Koyu Renk Tema</p>
                  </button>
                  <button className="sidebar__link" type="button" onClick="">
                    <p className="sidebar__link-title">Açık Renk Tema</p>
                  </button>
                </TabPane>
                <TabPane tabId="3">
                  <ProfileTasks />
                </TabPane>
              </TabContent>
            </div>
          </div>
        </Card>
      </Col>
    );
  }
}

export default ProfileTabs;
