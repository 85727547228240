import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';

const PointSizes = ({ metrics }) => {
  const options = {
    legend: {
      position: 'bottom',
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            color: 'rgb(75, 75, 75)',
            borderDash: [3, 3],
          },
          ticks: {
            fontColor: 'rgb(204, 204, 204)',
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: 'rgb(75, 75, 75)',
            borderDash: [3, 3],
          },
          ticks: {
            fontColor: 'rgb(204, 204, 204)',
          },
        },
      ],
    },
  };
  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">{metrics.title}</h5>
          </div>
          <Line data={metrics.data} options={options} />
        </CardBody>
      </Card>
    </Col>
  );
};

PointSizes.propTypes = {
  metrics: PropTypes.instanceOf(Object).isRequired,
};

export default PointSizes;
