import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import PanelTab from './components/PanelTab';
import API from '../../../shared/Api';

export default class KompresorDashboard extends Component {
  constructor() {
    super();
    this.state = {
      kompresor: [],
      kollektor: [],
      tuketim: [],
    };
  }

  componentDidMount() {
    this.energycartfunc('all');
  }

  componentWillUnmount() {
    console.log('r');
  }


  energycartfunc = (type) => {
    API({
      url: '/metrics/value',
      data: {
        metricId: 1015,
        body: [
          { paramType: 'Integer', paramValue: 25 },
          { paramType: 'Integer', paramValue: 1 },
        ],
      },
    }).then((response) => {
      if (type === 'all') {
        this.setState({
          kompresor: this.parseMP(response.data, 1),
          kollektor: this.parseMP(response.data, 8),
          tuketim: this.parseMP(response.data, 3),
        });
      } else if (type === 'kompresor') {
        this.setState({
          kompresor: this.parseMP(response.data, 1),
        });
      } else if (type === 'kollektor') {
        this.setState({
          kollektor: this.parseMP(response.data, 8),
        });
      } else if (type === 'tuketim') {
        this.setState({
          tuketim: this.parseMP(response.data, 3),
        });
      }
    });
  };

  parseMP = (data, mpId) => {
    const parsed = [];
    data.forEach((val) => {
      if (parseInt(val.param[2], 10) === mpId) {
        const cmpKey = this.findCmp(parsed, parseInt(val.param[5], 10));
        if (cmpKey !== false) {
          parsed[cmpKey].data.push({
            id: parseInt(val.param[7], 10),
            title: val.param[8],
            value: parseFloat(val.param[10]),
          });
        } else {
          parsed.push({
            id: parseInt(val.param[5], 10),
            title: val.param[6],
            data: [{
              id: parseInt(val.param[7], 10),
              title: val.param[8],
              value: parseFloat(val.param[10]),
            }],
          });
        }
      }
    });
    return parsed;
  };

  findCmp = (arr, id) => {
    let foundKey = false;
    arr.forEach((val, key) => {
      if (parseInt(val.id, 10) === parseInt(id, 10)) {
        foundKey = key;
      }
    });
    return foundKey;
  };

  refreshFnc = (metric) => {
    this.energycartfunc(metric);
  };

  render() {
    const { kompresor, kollektor, tuketim } = this.state;
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title" style={{ marginBottom: '30px' }}>Enerji Kartları</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <h5 style={{ marginBottom: '10px' }}>Kompresör Çıkışları</h5>
          </Col>
          <PanelTab title="Kompresör Çıkışları" data={kompresor} refreshFnc={() => this.refreshFnc('kompresor')} />
          <PanelTab title="Kollektör Çıkışları" data={kollektor} refreshFnc={() => this.refreshFnc('kollektor')} />
          <PanelTab title="Tüketim Departmanları" data={tuketim} refreshFnc={() => this.refreshFnc('tuketim')} />
        </Row>
      </Container>
    );
  }
}
