import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

class SidebarContent extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink title="Gösterge Paneli" icon="home" route="/dashboard_kompresor" onClick={this.hideSidebar} />
        </ul>
        <ul className="sidebar__block">
          <SidebarCategory title="Basınçlı Hava Sistemleri" icon="chart-bars">
            <SidebarLink title="Genel İzleme Ekranı" route="/kompresor/izleme" />
            <SidebarLink title="Anlık Durum" route="/kompresor/anlik" />
            <SidebarLink title="Kompresör-1" route="/kompresor/tablo1" />
            <SidebarLink title="Fabrika Mimarisi" route="/kompresor/fabrika" />
          </SidebarCategory>
          <SidebarCategory title="Elektrik İzleme" icon="chart-bars">
            <SidebarLink title="Genel İzleme Ekranı" route="/elektrik/izleme" />
            <SidebarLink title="Anlık Durum" route="/elektrik/anlik" />
            <SidebarLink title="Departman-1" route="/elektrik/tablo1" />
            <SidebarLink title="Fabrika Mimarisi" route="/elektrik/fabrika" />
          </SidebarCategory>
        </ul>
        <ul className="sidebar__block">
          <SidebarLink title="Ayarlar" icon="user" route="/account/profile" onClick={this.hideSidebar} />
        </ul>
        <ul className="sidebar__block">
          <SidebarLink title="Çıkış Yap" icon="exit" route="/log_in" />
        </ul>
        <ul className="sidebar__block">
          <SidebarLink
            title="Dökümasyon"
            icon="text-align-justify"
            route="/documentation/introduction"
            onClick={this.hideSidebar}
          />
        </ul>
      </div>
    );
  }
}

export default SidebarContent;
