import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Panel from './Panel';
import Table from './DataTable';

class TabPanel extends Component {
  static propTypes = {
    data: PropTypes.instanceOf(Array).isRequired,
    refreshFnc: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.test = null;
  }

  render() {
    const { data, refreshFnc } = this.props;
    return (
      <Container>
        <Row>
          {data.map(d => (
            <Col md={4} key={d.id}>
              <Panel
                xs={12}
                md={12}
                lg={12}
                divider
                title={d.title}
                refreshFnc={refreshFnc}
              >
                <Table data={d.data} />
              </Panel>
            </Col>
          ))}
        </Row>
      </Container>
    );
  }
}

export default withTranslation('common')(TabPanel);
