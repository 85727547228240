import React from 'react';
import { IgrRadialGaugeModule, IgrRadialGauge } from 'igniteui-react-gauges';
import PropTypes from 'prop-types';

IgrRadialGaugeModule.register();

const RadialGauge = ({ metrics }) => {
  const valueStyle = {
    textAlign: 'center',
    fontWeight: '900',
  };

  let titleText = metrics.value;
  if (metrics.formatText === 'currency') {
    titleText = (metrics.value * 1000).toLocaleString('en-US', {
      style: 'currency',
      currency: 'TRY',
    });
  }

  return (
    <div>
      <h4 className="text-center">{metrics.title}</h4>
      <IgrRadialGauge
        scaleStartAngle={135}
        scaleEndAngle={45}
        scaleBrush="DodgerBlue"
        scaleSweepDirection="Clockwise"
        scaleOversweep={1}
        scaleOversweepShape="Fitted"
        scaleStartExtent={0.45}
        scaleEndExtent={0.575}
        needlePivotBrush="#9f9fa0"
        height="250px"
        width="100%"
        minimumValue={0}
        value={metrics.value}
        maximumValue={metrics.maxVal}
        interval={metrics.ticks}
        transitionDuration={0.5}
      />
      <h5 style={valueStyle}>{titleText}</h5>
    </div>
  );
};

RadialGauge.propTypes = {
  metrics: PropTypes.instanceOf(Object).isRequired,
};

export default RadialGauge;
